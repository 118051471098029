import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import { ArticleListingProps } from './models';
import 'styles/main.scss';
import './ArticleListing.scss';

const ArticleListing: FC<{ data: ArticleListingProps }> = ({
  data: {
    articleListing: { title, image, seeMore, seoMetaTitle, seoMetaKeywords, seoMetaDescription },
    allArticle: { nodes },
  },
}) => (
  <Layout headerTransparent>
    <Seo {...{ title: seoMetaTitle, description: seoMetaDescription, keywords: seoMetaKeywords }} />
    <PageSchema
      type="WebPage"
      name={seoMetaTitle}
      data={{
        metaTitle: seoMetaTitle,
        metaDescription: seoMetaDescription,
        metaKeywords: seoMetaKeywords,
      }}
    />
    <div className="article-listing">
      <section id="article-listing__banner" className="text-center">
        <Image imageData={image} alt={image.altText} />
      </section>
      <section id="article-listing__middle">
        <div className="container">
          <div className="row">
            <div className="col py-4">
              <h2 className="line-primary">{title}</h2>
            </div>
          </div>
          <div className="row pb-5">
            {nodes.map((item, index) => (
              <div className="col-12 col-md-4 my-2" key={index}>
                <div className="article-listing__item h-100">
                  <div>
                    <Image imageData={item.image} alt={item.image.altText} />
                  </div>
                  <div className="px-3 text-center pb-3">
                    <h3 className="line-secondary pt-2 mb-5">{item.title}</h3>

                    <a href={item.link} className="btn btn-secondary text-white">
                      {seeMore}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    articleListing {
      title
      seoMetaTitle
      seoMetaKeywords
      seoMetaDescription
      seeMore
      image {
        fallbackUrl
        altText
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 800)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 800)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 800)
          }
        }
      }
    }
    allArticle {
      nodes {
        id
        title
        link
        body
        resume
        image {
          fallbackUrl
          altText
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 800)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 800)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 800)
            }
          }
        }
      }
    }
  }
`;

export default ArticleListing;
